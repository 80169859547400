<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2023-06-12 14:07:19
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-12-10 14:42:43
 * @Description: 
-->
<template>
	<div class="set_page">
		<div class="item">
			<div class="text_1">转台发送短信选择：</div>
			<a-switch default-checked :checked="checked" data-name="change_room_sms" @change="onChange" />
			<div class="text_2">开启后，转台时可选择是否发送短信给预订人。</div>
		</div>
		<div class="item">
			<div class="text_1">取消发送短信选择：</div>
			<a-switch :checked="cancelOrderChecked" data-name="cancel_order_sms" @change="onChange" />
			<div class="text_2">开启后，取消预订时可选择是否发送短信给预订人。</div>
		</div>
		<!-- <div class="item">
			<div class="text_1">修改预订发送短信选择：</div>
			<a-switch :checked="editOrderChecked" data-name="edit_order_sms" @change="onChange" />
			<div class="text_2">开启后，修改预订后可选择是否发送短信。</div>
		</div> -->
		<div class="item">
			<div class="text_1">订单无法取消和修改转台：</div>
			<a-switch :checked="disableCancelChange" data-name="disable_cancel_change" @change="onChange" />
			<div class="text_2">开启后，电脑网页工作台只可以查看其他人预订的订单，无法取消和修改转台。隐藏除前台自己预订的订单的转台按钮。</div>
		</div>
		<div class="item">
			<div class="text_1">手机号是否必填：</div>
			<a-switch :checked="mustNeedPhone" data-name="must_need_phone" @change="onChange" />
			<div class="text_2">开启后，小程序预订信息手机号为必填内容。</div>
		</div>
		<div class="item">
			<div class="text_1">关闭预订人云通讯录匹配：</div>
			<a-switch :checked="cloudContactList" data-name="cloud_contact_list" @change="onChange" />
			<div class="text_2">开启后，小程序预订下单时预订人处将不再匹配云通讯录信息。</div>
		</div>
		<!-- <div class="item">
			<div class="text_1">多餐段设置：</div>
			<a-switch :checked="multipleMealTime" data-name="multiple_meal_time" @change="onChange" />
			<div class="text_2">小程序端和电脑端增加开启的对应餐段，早茶餐段分割时间为6:00-9:30每半小时，夜宵为21:30-凌晨3:00每半小时。</div>
		</div> -->
		<div class="item">
			<div class="text_1">节假日二轮餐段设置：</div>
			<a-switch :checked="holidayTwiceMealTime" data-name="holiday_twice_meal_time" @change="onChange" />
			<div class="text_2">开启后，节假日的午餐晚餐各变为第一餐段和第二餐段。</div>
		</div>
		<div class="item">
			<div class="text_1">邀请函不显示桌型：</div>
			<a-switch :checked="hideRoomType" data-name="hide_room_type" @change="onChange" />
			<div class="text_2">开启后，将不在邀请函显示餐位的桌型。</div>
		</div>
		<div class="item">
			<div class="text_1">邀请函不显示预订人数：</div>
			<a-switch :checked="invitationHideArriveCount" data-name="invitation_hide_arrive_count" @change="onChange" />
			<div class="text_2">开启后，将不在邀请函上显示预订人数。</div>
		</div>
		<div class="item">
			<div class="text_1">邀请函不显示餐段名：</div>
			<a-switch :checked="invitationHideDiningType" data-name="invitation_hide_dining_type" @change="onChange" />
			<div class="text_2">开启后，小程序邀请函将不显示餐段名。</div>
		</div>
		<div class="item">
			<div class="text_1">邀请函不显示到店时间：</div>
			<a-switch :checked="invitationHideArriveTime" data-name="invitation_hide_arrive_time" @change="onChange" />
			<div class="text_2">开启后，小程序邀请函将不显示预计到店时间。</div>
		</div>
		<div class="item">
			<div class="text_1">邀请函封面图显示日期时间：</div>
			<a-switch :checked="invitationShareShowDate" data-name="invitation_share_show_date" @change="onChange" />
			<div class="text_2">开启后，小程序邀请函转发时封面图将显示预订就餐的日期和时间。（需上传门店Logo才可生效）。</div>
		</div>
		<div class="item">
			<div class="text_1">邀请函是否显示农历：</div>
			<a-switch :checked="invitationShowChineseCalendar" data-name="invitation_show_chinese_calendar" @change="onChange" />
			<div class="text_2">开启后，小程序邀请函将显示预订的农历日期。</div>
		</div>
		<div class="item">
			<div class="text_1">电子预订本是否显示全部：</div>
			<a-switch :checked="stapleShowAllRooms" data-name="staple_show_all_rooms" @change="onChange" />
			<div class="text_2">开启后，电子预订本显示【全部】分类展示门店所有餐位情况。</div>
		</div>
		<div class="item">
			<div class="text_1">邀请函预点菜：</div>
			<a-switch :checked="showOrderDishes" data-name="show_order_dishes" @change="onChange" />
			<div class="text_2">开启后，小程序预订下单的预点菜功能将被打开</div>
		</div>
	</div>
</template>

<script>
import {setConfig, getConfig} from '@/api/personalization'
export default {
	data() {
		return {
			checked: false,
			cancelOrderChecked: false,
			editOrderChecked: false,
			disableCancelChange: false,
			multipleMealTime: false,
			holidayTwiceMealTime: false,
			hideRoomType: false,
			invitationShareShowDate: false,
			invitationHideArriveCount: false,
			cloudContactList: false,
			mustNeedPhone: false,
			invitationHideDiningType: false,
			invitationHideArriveTime: false,
			invitationShowChineseCalendar: false,
			stapleShowAllRooms: false,
			showOrderDishes: false
		}
	},
	created() {
		this.getConfig()
	},
	methods: {
		/**
		 * @description: 获取个性化设置列表
		 * @return {*}
		 */
		async getConfig() {
			this.$store.dispatch('loading/startContentLoading')
			const {code, data = {}} = await getConfig()
			if (code === 0) {
				const {change_room_sms, cancel_order_sms, edit_order_sms, disable_cancel_change, multiple_meal_time, holiday_twice_meal_time, hide_room_type, invitation_share_show_date, invitation_hide_arrive_count, cloud_contact_list, must_need_phone, invitation_hide_dining_type, invitation_hide_arrive_time, invitation_show_chinese_calendar, staple_show_all_rooms, show_order_dishes} = data
				this.checked = change_room_sms / 1 !== 0
				this.cancelOrderChecked = parseInt(cancel_order_sms || '0') !== 0
				this.editOrderChecked = parseInt(edit_order_sms || '0') !== 0
				this.disableCancelChange = parseInt(disable_cancel_change || '0') !== 0
				this.multipleMealTime = parseInt(multiple_meal_time || '0') !== 0
				this.holidayTwiceMealTime = parseInt(holiday_twice_meal_time || '0') !== 0
				this.hideRoomType = parseInt(hide_room_type || '0') !== 0
				this.invitationShareShowDate = parseInt(invitation_share_show_date || '0') !== 0
				this.invitationHideArriveCount = parseInt(invitation_hide_arrive_count || '0') !== 0
				this.cloudContactList = parseInt(cloud_contact_list || '0') !== 0
				this.mustNeedPhone = parseInt(must_need_phone || '0') !== 0
				this.invitationHideDiningType = parseInt(invitation_hide_dining_type || '0') !== 0
				this.invitationHideArriveTime = parseInt(invitation_hide_arrive_time || '0') !== 0
				this.invitationShowChineseCalendar = parseInt(invitation_show_chinese_calendar || '0') !== 0
				this.stapleShowAllRooms = parseInt(staple_show_all_rooms || '0') !== 0
				this.showOrderDishes = parseInt(show_order_dishes || '1') !== 0
			}
			this.$store.dispatch('loading/endContentLoading')
		},
		/**
		 * @description: 设置开关
		 * @return {*}
		 * @param {*} checked
		 * @param {*} ev
		 */
		async onChange(checked, ev) {
			const config_name = ev.target.dataset.name
			this[
				{
					change_room_sms: 'checked',
					cancel_order_sms: 'cancelOrderChecked',
					edit_order_sms: 'editOrderChecked',
					disable_cancel_change: 'disableCancelChange',
					multiple_meal_time: 'multipleMealTime',
					holiday_twice_meal_time: 'holidayTwiceMealTime',
					hide_room_type: 'hideRoomType',
					invitation_share_show_date: 'invitationShareShowDate',
					invitation_hide_arrive_count: 'invitationHideArriveCount',
					cloud_contact_list: 'cloudContactList',
					must_need_phone: 'mustNeedPhone',
					invitation_hide_dining_type: 'invitationHideDiningType',
					invitation_hide_arrive_time: 'invitationHideArriveTime',
					invitation_show_chinese_calendar: 'invitationShowChineseCalendar',
					staple_show_all_rooms: 'stapleShowAllRooms',
					show_order_dishes: 'showOrderDishes'
				}[config_name]
			] = checked
			const {code} = await setConfig({
				config_name,
				config_value: checked ? 1 : 0
			})
			if (code === 0) {
				this.$message.success('设置成功！')
			}
		}
	}
}
</script>

<style scoped lang="less">
.set_page {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 30px 0;
	background-color: #fff;
	border-radius: 4px;
	box-sizing: border-box;
}
.set_page > .item {
	display: flex;
	flex-direction: row;
	margin-top: 15px;
	align-items: center;
}
.text_1 {
	width: 220px;
	font-weight: 500;
	font-size: 16px;
	color: #333333;
	margin-right: 10px;
	text-align: right;
}
.text_2 {
	font-weight: 400;
	font-size: 14px;
	color: #666666;
	margin-left: 10px;
}
</style>
